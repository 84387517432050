<template>
  <section id="login" class="vh-100 bg-bgPrimary d-flex align-items-center justify-content-center">
    <b-card class="overflow-hidden" style="min-height: 303px">
      <b-card-title class="text-center">
        <h4 class="text-uppercase">Evipstudio CRM</h4></b-card-title
      >
      <div v-if="isVerifyTokenRequestPassword" class="d-flex">
        <b-card-body>
          <!--  RESET PASSWORD FORM BEGIN -->
          <b-form
            class="login-signup animate__fadeIn animate__animated"
            @submit.prevent="onResetPassword"
          >
            <b-form-group>
              <b-form-input
                required
                disabled
                class="my-1"
                id="login_page-password"
                placeholder="E-mail"
                :value="form.email"
              />
            </b-form-group>

            <b-form-group>
              <b-form-input
                required
                class="my-1"
                type="password"
                id="forgot_page-password"
                :placeholder="$t('FORM.PASSWORD')"
                v-model="$v.form.password.$model"
              />
            </b-form-group>

            <b-form-group>
              <b-form-input
                required
                class="my-1"
                id="forgot_page-passwordRepeat"
                type="password"
                :placeholder="$t('FORM.PASSWORD_REPEAT')"
                v-model="form.plainPassword"
              />
            </b-form-group>

            <b-button
              type="submit"
              class="ml-auto w-100 text-white"
              variant="success"
              :class="{ disabled: $v.form.$invalid }"
              >{{ $t("FORM.CHANGE_PASSWORD") }}</b-button
            >
            <b-button
              class="ml-1 mt-3 w-100 text-center font-weight-bold"
              variant="transparent"
              size="sm"
              @click="$router.push('/')"
              >{{ $t("FORM.CANCEL") }}</b-button
            >
          </b-form>
        </b-card-body>
      </div>
      <b-card-body v-else>
        <h4>
          {{ $t("ALERT.TOKEN_PASSWORD_EXPIRED") }}
        </h4>
        <b-button
          class="mx-auto d-block mt-5 text-white"
          variant="primary"
          @click="$router.push('/login?resetPassword=true')"
          >Generate</b-button
        >
      </b-card-body>
      <!--  RESET PASSWORD FORM END -->
    </b-card>
    <!--  RESET PASSWORD VALIDATION BEGIN -->

    <b-list-group class="ml-3 small" v-if="isVerifyTokenRequestPassword">
      <b-list-group-item :class="validateField('password', 'containsUppercase')">
        <i class="fas fa-check pr-2"></i>Posiada dużą literę
      </b-list-group-item>
      <b-list-group-item :class="validateField('password', 'containsNumber')">
        <i class="fas fa-check pr-2"></i>Posiada cyfrę
      </b-list-group-item>
      <b-list-group-item :class="validateField('password', 'minLength', true)">
        <i class="fas fa-check pr-2"></i>Ma minimalnie 8 znaków
      </b-list-group-item>
      <b-list-group-item :class="validateField('password', 'sameAs')">
        <i class="fas fa-check pr-2"></i>Są takie same
      </b-list-group-item>
    </b-list-group>
    <!--  RESET PASSWORD VALIDATION END -->
    <div class="copyright">Wszelkie prawa zastrzeżone. Powered by Evipstudio.pl</div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { minLength, required, sameAs } from "vuelidate/lib/validators";
import { CHANGE_PASSWORD, VERIFY_TOKEN } from "@/core/store/auth.module";

export default {
  name: "Login",
  data() {
    return {
      form: {
        token: null,
        email: null,
        password: "",
        plainPassword: "",
      },
    };
  },
  validations: {
    form: {
      password: {
        required,
        containsUppercase(value) {
          return /[A-Z]/.test(value);
        },
        containsNumber(value) {
          return /[1-9]/.test(value);
        },
        minLength: minLength(8),
        sameAs: sameAs(function () {
          return this.form.plainPassword;
        }),
      },
      plainPassword: {
        required,
        containsUppercase(value) {
          return /[A-Z]/.test(value);
        },
        containsNumber(value) {
          return /[1-9]/.test(value);
        },
        minLength: minLength(8),
      },
    },
  },

  methods: {
    validateState(name, notForm) {
      const { $dirty, $error } = notForm ? this.$v[name] : this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateField(name, validName) {
      return this.$v.form[name][validName] && this.$v.form[name].$model.length > 1
        ? "text-success"
        : "text-danger";
    },
    onResetPassword() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$store
        .dispatch(CHANGE_PASSWORD, this.form)
        .then(() => {
          this.$bvToast.toast("Success", {
            title: "Change password success",
            variant: "success",
            appendToast: true,
          });

          setTimeout(() => {
            this.$router.push("/");
          }, 3000);
        })
        .catch(() => {
          this.$bvToast.toast("Failed", {
            title: "Change password failed",
            variant: "danger",
            appendToast: true,
          });
        });
    },
  },
  computed: {
    ...mapGetters(["isVerifyTokenRequestPassword"]),
  },
  mounted() {
    this.form.email = this.$route.query.email;
    this.form.token = this.$route.query.token;
    this.$store.dispatch(VERIFY_TOKEN, { email: this.form.email, token: this.form.token });
  },
};
</script>

<style lang="scss">
.copyright {
  position: absolute;
  bottom: 10px;
  text-align: center;
  padding-top: 35px;
  color: #a6a6a6;
  font-size: 14px;
  width: 100%;
}
</style>
